import React from 'react'
import Layout from '../components/Layout'
import PageHero from '../components/PageHero'
import styled from 'styled-components'

const assets = {
  support: require('../resources/img/about.png'),
  support_email: require('../resources/img/support_email.svg'),
  support_sales: require('../resources/img/support_sales.svg'),
  support_call: require('../resources/img/support_call.svg'),
  support_whatsapp: require('../resources/img/support_whatsapp.svg')
}


export default () => (
  <Layout page="apropos" className="distort-blue">

    <PageHero title="Politique de confidentialité"
              content={
                <div>
                  La protection de votre confidentialité nous tient à coeur.
                   VitePay s'engage à protéger la confidentialité, 
                   la sécurité et l'exactitude des renseignements personnels qu'ils recueillent, 
                   utilisent ou communiquent conformément à la législation applicable.
                </div>
              }/>


    <p>&nbsp;</p>


    <div className="text-center mt-5" id="politique_conf">
      <p className="lead" style={{textAlign: "start", paddingLeft: "3rem", paddingRight: "3rem"}}>
        Le présent avis représente notre engagement à protéger votre vie privée et le fondement sur lequel nous traiterons toutes les données personnelles 
        que nous collectons à votre sujet ou que vous nous fournissez. Il s’applique :<br/>
        Aux fonctionnalités et aux services du site web/portail qui vous sont fournis lorsque vous visitez nos sites web, nos portails, 
        ou les pastilles de paiement que nos clients peuvent utiliser sur leurs sites web ; lorsque vous demandez à utiliser et/ou que vous utilisez les produits et services de Vitepay 
        (y compris les systèmes de récompense ou de fidélisation, à base de points de fidélité ou non [« Fidélité » ou « Promotion » ou « Revente »]).
        Veuillez lire attentivement ce qui suit pour comprendre nos points de vue et pratiques concernant vos données personnelles et nos méthodes de traitement.<br/><br/>
        Vitepay adopte une « approche par couche » pour expliquer ses pratiques de confidentialité, comme recommandé par les organismes de régulation. Cela signifie que nous nous efforçons de vous fournir initialement 
        les informations de confidentialité clés sous la forme de cet avis de confidentialité abrégé. Si vous désirez consulter l’avis de confidentialité complet, vous pouvez cliquer ici. Nos informations de contact sont 
        indiquées à la fin de cet avis, pour vos éventuels commentaires ou demandes de renseignements.
      </p>
    </div>

    <p>&nbsp;</p>

    <div className="clearfix container mt-5 mb-5">
      <APS className="row  circle-bg-grey" style={{ backgroundPosition: '100% 0' }}>
        <div className="col-6">
          <h3>I. INFORMATIONS QUE NOUS POUVONS RECUEILLIR AUPRÈS DE VOUS</h3>
          <p>
            Vous pouvez nous fournir des informations, par exemple lorsque vous posez des questions sur les services Vitepay ou que déposez une demande relative à ces services ou que vous vous enregistrez pour utiliser ou que vous utilisez des services Vitepay, 
            en remplissant des formulaires sur l’un de nos sites Web, en participant à l’un de nos événements ou en correspondant avec nous par téléphone, 
            email, chat web ou autrement. Nous pouvons les recueillir en partie par des moyens automatiques, par exemple en utilisant des cookies lorsque vous visitez nos sites web. 
            Veuillez lire notre Politique relative aux cookies pour en savoir plus. Nous pouvons également obtenir des données auprès de tiers, comme des agences de renseignements commerciaux et de prévention des fraudes.
          </p>
        </div>
        <div className="col-6">
          <h3>II. UTILISATION FAITE DE L’INFORMATION</h3>
          <p>
          Nous utilisons les informations que nous recueillons à votre sujet afin de vous proposer les produits que nous offrons, de vous informer des changements apportés à nos produits et d’améliorer nos produits. 
          Nous utilisons également ces informations pour vous fournir des renseignements sur d’autres produits, que nous, ou certains tiers, offrons, qui sont similaires à ceux que vous avez utilisés ou demandés ou que nous 
          jugeons susceptibles de vous intéresser. Si vous utilisez l’un de nos produits financiers, nous utiliserons également vos informations pour évaluer votre situation financière et tenter d’identifier et de poursuivre les fraudes.
          </p>
        </div>
        <div className="col-6">
          <h3>III. COMMUNICATION DE VOS DONNÉES PERSONNELLES</h3>
          <p>
            Nous ne communiquerons pas vos données personnelles à quiconque, sauf comme décrit dans le présent avis. Par ailleurs, nous pouvons partager vos données personnelles avec des tiers pour prévenir les crimes et réduire le risque, 
            pour satisfaire aux exigences de la loi, si nous le jugeons approprié, pour satisfaire une procédure légale ou protéger les droits ou les biens de Vitepay, de nos clients ou d’autres personnes.
          </p>

          <h3>V. VOS DROITS</h3>
          <p>
            Au titre de la législation de protection des données, vous pouvez disposer, le cas échéant, de certains droits, y compris d’un droit d’accès, de correction, d’actualisation ou d’effacement de vos données personnelles, 
            la Société s’engage à observer la discrétion la plus stricte sur toutes les Informations Confidentielles se rapportant aux activités du Client auxquelles elle aura accès, sauf non frauduleuse .<br/><br/>
            Ainsi, la Société ne divulguera et ne laissera divulguer toute ou partie desdites informations sauf accord écrit du Client.<br/><br/>
            Tous ses serveurs étant hébergés dans le cloud distribué et protégé par un cryptage 128bits, la Société garantie une fiabilité à la hauteur des dernières technologies du service VitePay.
          </p>
        </div>
        <div className="col-6">
          <h3>IV. LIEU DE CONSERVATION DE VOS DONNÉES PERSONNELLES ET SÉCURITÉ DES DONNÉES</h3>
          <p>
            Les informations que nous recueillons auprès de vous peuvent être transférées, conservées et traitées par des destinataires situés en dehors de l’Espace économique Ouest-Africaine (« UEMOA »). 
            Dans ces pays, les normes relatives à la protection des données peuvent être différentes de (et, dans certains cas, inférieures à) celles du pays dans lequel vous résidez. Nous prendrons toutes les 
            étapes nécessaires pour nous assurer que les destinataires utiliseront et protègeront vos données d’une manière aussi sécurisée que celle que nous utilisons.<br/><br/>
            Si nous vous avons donné (ou si vous avez choisi) un mot de passe, un code d’accès ou tout autre moyen ou méthode d’accès ou d’authentification sécurisé qui vous permet d’accéder à certaines parties de notre site, 
            vous êtes tenu de garder ce mot de passe confidentiel et de respecter nos instructions. Vous ne devez pas partager des identifiants avec quiconque, 
            et vous autorisez Vitepay à agir selon les instructions et les informations provenant de toute personne qui utilise vos identifiants.<br/><br/>
            La transmission d’informations via Internet n’est pas complètement sécurisée. Bien que nous nous efforçons de protéger au mieux vos données personnelles, 
            nous ne pouvons pas garantir la sécurité des données que vous transmettez sur notre site, sauf si vous communiquez avec nous par le biais d’un canal sécurisé que nous avons mis à disposition. 
            Une fois vos informations reçues, nous utiliserons des procédures strictes et des dispositifs de sécurité pour tenter de prévenir l’accès non autorisé.
          </p>
        </div>
      </APS>
    </div>

  </Layout>
)


const ContactBlock = styled.div`
  background: #FFF;
  border-radius: 6px;
  padding: 30px;
  box-shadow: 0px 1px 6px rgba(0,0,0,0.05);
  margin-top: 60px;
  margin-bottom: 60px;

  img {
    width: 48px;
    margin-bottom: 16px;
  }
  h4 {
    margin-bottom: 15px;
  }
  
  p {
    color: #565758;
    font-size: 0.95em;
  }
  
  a {
    font-size: 0.9em;
  }
`

const APS = styled.div` 
  h3 {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    color: rgba(26,26,26,.9);
    font-size: 13px;
  }
  
  p {
    color: #5c5c5c;
    padding: 20px 30px 20px 0;  
    text-align: justify;
    
    a {
      font-weight: 500;
    }
    
    strong {
      font-weight: 500;
    }
    
  }
  
`
